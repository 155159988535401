import React from 'react';

const AboutPage = ({ lang }) => (
	<section>
		<div className="wrapper collection">
			<div className="collection-header">
				<div className="collection-icon" style={{ backgroundImage: 'url(/icons/about.svg)', maskImage: 'url(/icons/about.svg)', WebkitMaskImage: 'url(/icons/about.svg)' }} />
				<h1>{ lang === 'es' ? 'Acerca de' : 'About' }</h1>
			</div>

			{ lang === 'es' ?
				<>
					<div className="collection-body">
						<h2>El Catecismo Menor de Lutero</h2>

						<p>La palabra <em>catecismo</em> ha sido empleada durante cientos de años para describir los fundamentos de la fe cristiana. En la edad Media, catecismo ya se refería a los tres elementos fundamentales que debía conocer todo cristiano: los Diez Mandamientos, el Credo Apostólico y el Padre Nuestro.</p>

						<p>Hace quinientos años, el reformista Martín Lutero predicaba sobre las &ldquo;Creencias fundamentales&rdquo; (las enseñanzas básicas de la fe) a su congregación en Wittenberg, Alemania. En 1529, Lutero redactó explicaciones breves de estas partes fundamentales extraídas de sus sermones, junto con explicaciones de los sacramentos del bautismo y la comunión. A esas explicaciones les agregó otros materiales relacionados con las prácticas cristianas en el hogar y así publicó su obra <em>El catecismo Menor de Martín Lutero</em> (1529).</p>

						<p>Lutero escribió el catecismo menor para el hogar, de modo que los padres pudieran explicarles a sus hijos de manera muy simple las partes fundamentales de la fe cristiana. Esas preguntas y respuestas básicas nos sirven de guía y nos acompañan desde hace 500 años.</p>

						<p>Las ediciones de los libros impresos así como el libro electrónico de ese catecismo han sido publicados por Augsburg Fortress en varios formatos.</p>

						<p><a href="https://www.augsburgfortress.org/store/product/9781506446301/El-Catecismo-Menor-de-Lutero-Luthers-Small-Catechism-Bilingual-Edition-5-pkg" target="_blank" rel="noreferrer">El Catecismo Menor de Lutero / Luther&rsquo;s Small Catechism</a></p>

						<h3>Aplicación Básica</h3>

						<p><em>El catecismo Menor de Lutero</em> se puede leer en inglés o español. También puede seleccionar una versión en inglés o español en <em>Preferencias</em>.</p>

						<h2>Sobre Augsburg Fortress</h2>
						<p><a href="https://www.augsburgfortress.org" target="_blank" rel="noreferrer">Augsburg Fortress</a> es el ministerio de publicaciones de la Iglesia Evangélica Luterana en América (IELA). Nuestra misión es proporcionar recursos que transmitan el mensaje del Evangelio, profundicen la fe y enriquezcan la vida de la iglesia y de las comunidades apoyadas. Visite nuestro <a href="https://www.augsburgfortress.org" target="_blank" rel="noreferrer">sitio web</a>.</p>

						<h3>Aviso de Copyright y Reconocimientos</h3>
						<p><em><strong>El Catecismo Menor de Lutero</strong></em><br/>
						Texto en español reimpreso del <em>Catecismo Menor de Martín Lutero</em>. &copy; 1990, 2006 Augsburg Fortress.</p>

						<p>Todos los derechos reservados. Ninguna parte de este libro podrá ser reproducida en modo alguno ni guardada en un sistema de recuperación de datos ni transmitida, de forma o modo alguno, sin obtener el previo consentimiento por escrito de Augsburg Fortress. Augsburg Fortress lo invita a compartir enlaces a esta aplicación en el sitio web y en otras redes sociales. Para obtener permiso para otros usos, contacte a <a href="https://www.augsburgfortress.org/info/permissions/" target="_blank" rel="noreferrer">Augsburg Fortress</a>.</p>

						<p>Nicholas Markell, Markell Studios y Gertrud Mueller Nelson son nuestros artistas litúrgicos.</p>
					</div>
				</>
			:
				<>
					<div className="collection-body">
						<h2>Luther&rsquo;s Small Catechism</h2>

						<p>The word <em>catechism</em> has been used for hundreds of years to describe basic instruction about the Christian faith. By the Middle Ages <em>catechism</em> had come to refer to three things that all Christians should know: the Ten Commandments, the Apostles&rsquo; Creed, and the Lord&rsquo;s Prayer. About 500 years ago, the reformer Martin Luther preached on these &ldquo;chief parts&rdquo; (principal faith teachings) to the congregation in Wittenberg, Germany. In 1529, Luther prepared brief explanations of these chief parts drawn from these sermons, along with explanations of the sacraments of baptism and communion. These were gathered together with other material related to Christian practices in the home and published as <em>The Small Catechism of Martin Luther</em> (1529).</p>

						<p>Luther wrote the <em>Small Catechism</em> for the home, so that parents could explain to their children in simple terms the most important things in the Christian faith. These basic questions and answers continue to be a guide and companion for people 500 years later.</p>

						<p style={{ marginBottom: '0.25rem' }}>Several editions of Luther&rsquo;s Small Catechism are available from Augsburg Fortress in print and as eBooks:</p>

						<ul>
							<li><a href="https://www.augsburgfortress.org/store/product/9781506415987/Luthers-Small-Catechism-Pocket-Edition-5-pkg" target="_blank" rel="noreferrer">Pocket Edition</a></li>
							<li><a href="https://www.augsburgfortress.org/store/productgroup/2022/Luthers-Small-Catechism-Study-Edition" target="_blank" rel="noreferrer">Study Edition</a></li>
							<li><a href="https://www.augsburgfortress.org/store/product/9781506446318/Luthers-Small-Catechism-Gift-Edition-Revised-With-Evangelical-Lutheran-Worship-Texts" target="_blank" rel="noreferrer">Gift Edition</a></li>
							<li><a href="https://www.augsburgfortress.org/store/product/9781506446301/El-Catecismo-Menor-de-Lutero-Luthers-Small-Catechism-Bilingual-Edition-5-pkg" target="_blank" rel="noreferrer">Bilingual Edition</a></li>
							<li><a href="https://www.augsburgfortress.org/store/product/9781506458595/Luthers-Small-Catechism-with-African-Descent-Reflections" target="_blank" rel="noreferrer">Luther&rsquo;s Small Catechism with African Descent Reflections</a></li>
							<li><a href="https://www.augsburgfortress.org/store/product/9781506431444/Luthers-Small-Catechism-Enlarged-Study-Edition" target="_blank" rel="noreferrer">Enlarged Study Edition</a></li>
						</ul>
						
						<p style={{ marginBottom: '0.25rem' }}>Take your study of Luther&rsquo;s Small Catechism further with the following resources:</p>
						
						<ul>
							<li><a href="https://www.augsburgfortress.org/store/category/286576/By-Heart" target="_blank" rel="noreferrer">By Heart: Conversations with Martin Luther&rsquo;s Small Catechism</a></li>
							<li><a href="https://www.augsburgfortress.org/store/product/9781506432472/The-Small-Catechism-1529" target="_blank" rel="noreferrer">Small Catechism, 1529: Annotated Luther Study Edition</a></li>
							<li><a href="https://www.augsburgfortress.org/store/product/9781506480121/Praying-the-Catechism-Revised-and-Expanded-Edition" target="_blank" rel="noreferrer">Praying the Catechism, Revised and Expanded Edition</a></li>
						</ul>

						<h2>Publisher</h2>
						<p><a href="https://www.augsburgfortress.org" target="_blank" rel="noreferrer">Augsburg Fortress</a> is the publishing ministry of the Evangelical Lutheran Church in America (ELCA). We are dedicated to providing resources that communicate the gospel, enhance faith, and enrich the life of the church and the communities it serves. Visit our <a href="https://www.augsburgfortress.org" target="_blank" rel="noreferrer">website</a>.</p>

						<h3>Copyright Notices and Credits</h3>
						<p><em><strong>Luther&rsquo;s Small Catechism</strong></em><br/>
						Copyright &copy; 2000, 2006, 2016, 2022 Augsburg Fortress.</p>

						<p>All rights reserved. No part of this material may be reproduced or stored in a retrieval system or transmitted, in any form or by any means, without prior written permission of Augsburg Fortress. Augsburg Fortress invites you to share links to this app on your website and other social networks. To obtain permission for other uses, please contact <a href="https://www.augsburgfortress.org/info/permissions/" target="_blank" rel="noreferrer">Augsburg Fortress</a>.</p>

						<p>Nicholas Markell, Markell Studios, and Gertrud Mueller Nelson, liturgical artists</p>

						<p>The English translations of the Lord&rsquo;s Prayer and the Apostles&rsquo; Creed are prepared by the English Language Liturgical Consultation, copyright &copy; 1988.</p>
					</div>
				</>
			}
		</div>
	</section>
);

export default AboutPage;