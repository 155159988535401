import { initializeApp } from 'firebase/app';
import { getAnalytics, setConsent } from 'firebase/analytics';
import { getFirestore, collection, doc, enableMultiTabIndexedDbPersistence } from 'firebase/firestore';
// import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, signOut, updatePassword } from 'firebase/auth';
// import { getDatabase, ref } from 'firebase/database';
// import { getStorage } from 'firebase/storage';
// import { getFunctions } from 'firebase/functions';
import idbReady from 'safari-14-idb-fix';

const config = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const basicDatabase = 'basic';
// const premiumDatabase = 'premium'
const db = basicDatabase;

class Firebase {
	constructor() {		
		this.app = initializeApp(config);
		this.firestore = getFirestore(this.app);
		this.analytics = getAnalytics(this.app);
		// this.auth = getAuth(this.app);
		// this.database = getDatabase(this.app);
		// this.storage = getStorage(this.app);
		// this.functions = getFunctions(this.app);

		idbReady().then(() => {
			enableMultiTabIndexedDbPersistence(this.firestore)
				.catch((error) => {
					console.error(error);
				});
		});
	}

	// Analytics API
	doSetConsent = (ConsentStatusString) => {
		setConsent({
			'ad_storage': ConsentStatusString,
			'analytics_storage': ConsentStatusString
		});
	}

	// Firestore Database API
	collection = id => doc(this.firestore, `${db}`, `${id}`);
	collections = () => collection(this.firestore, `${db}`);

	// Auth API
	// doCreateUserWithEmailAndPassword = (email, password) =>
	// 	createUserWithEmailAndPassword(this.auth, email, password);

	// doSignInWithEmailAndPassword = (email, password) =>
	// 	signInWithEmailAndPassword(this.auth, email, password);

	// doSignOut = () => signOut(this.auth);

	// doPasswordReset = email => sendPasswordResetEmail(this.auth, email);

	// doPasswordUpdate = password =>
	// 	updatePassword(this.auth.currentUser, password);

	// Merge Auth and DB User API
	// onAuthUserListener = (next, fallback) =>
	// 	onAuthStateChanged(this.auth, (authUser) => {
	// 		if (authUser) {
	// 			this.user(authUser.uid)
	// 				.once('value')
	// 				.then(snapshot => {
	// 					const dbUser = snapshot.val();

	// 					// default empty roles
	// 					if (!dbUser.isAdmin) {
	// 						dbUser.isAdmin = 0;
	// 					}

	// 					// merge auth and db user
	// 					authUser = {
	// 						uid: authUser.uid,
	// 						email: authUser.email,
	// 						...dbUser
	// 					};

	// 					next(authUser);
	// 				});
	// 		} else {
	// 			fallback();
	// 		}
	// 	});

	// User API
	// user = uid => ref(this.database, `users/${uid}`);
	// users = () => ref(this.database, 'users');
}

export default Firebase;