import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';

class Navigation extends Component {
	render() {
		return (
			<AuthUserContext.Consumer>
				{authUser =>
					authUser ? (
						<NavigationAuth authUser={authUser} lang={this.props.lang} />
					) : (
						<NavigationNonAuth lang={this.props.lang} />
					)
				}
			</AuthUserContext.Consumer>
		);
	}
}

const NavigationAuth = ({ lang }) => {
	return (
		<nav>
			<ul>
				<li>
					<NavLink to={ROUTES.HOME} exact={true}>
						<div className="icon home" />	
						<span>{ lang === 'es' ? 'Inicio' : 'Home' }</span>
					</NavLink>
				</li>
				<li>
					<NavLink to='/about'>
						<div className="icon about" />	
						<span>{ lang === 'es' ? 'Acerca de' : 'About' }</span>
					</NavLink>
				</li>
				<li>
					<NavLink to={ROUTES.HELP}>
						<div className="icon help" />
						<span>{ lang === 'es' ? 'Ayuda' : 'Help' }</span>
					</NavLink>
				</li>
				<li>
					<NavLink to={ROUTES.SETTINGS}>
						<div className="icon settings" />
						<span>{ lang === 'es' ? 'Preferencias' : 'Settings' }</span>
					</NavLink>
				</li>
				<li>
					<NavLink to={ROUTES.ACCOUNT}>
						<div className="icon account" />
						<span>{ lang === 'es' ? 'Cuenta' : 'Account' }</span>
					</NavLink>
				</li>
			</ul>
		</nav>
	);
};

const NavigationNonAuth = ({ lang }) => {
	return (
		<nav>
			<ul>
				<li>
					<NavLink to={ROUTES.HOME} exact={true}>
						<div className="icon home" />	
						<span>{ lang === 'es' ? 'Inicio' : 'Home' }</span>
					</NavLink>
				</li>
				<li>
					<NavLink to='/about'>
						<div className="icon about" />	
						<span>{ lang === 'es' ? 'Acerca de' : 'About' }</span>
					</NavLink>
				</li>
				<li>
					<NavLink to={ROUTES.HELP}>
						<div className="icon help" />
						<span>{ lang === 'es' ? 'Ayuda' : 'Help' }</span>
					</NavLink>
				</li>
				<li>
					<NavLink to={ROUTES.SETTINGS}>
						<div className="icon settings" />
						<span>{ lang === 'es' ? 'Preferencias' : 'Settings' }</span>
					</NavLink>
				</li>
			</ul>
		</nav>
	);
};

export default withRouter(Navigation);