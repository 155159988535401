import React, { useEffect, useState } from 'react';

const Spinner = ({ style, button, delay = 1000 }) => {
	const [stillLoading, setStillLoading] = useState(false);
	const spinnerDisplayStyle = stillLoading ? { display: 'block' } : { display: 'none' };
	const spinnerPositionStyle = button && { position: 'absolute' };

	useEffect(() => {
		const timer = setTimeout(() => setStillLoading(true), delay);
		return () => {
			clearTimeout(timer);
		}
	}, [delay]);

	return (
		<div className='spinner' style={{ ...spinnerDisplayStyle, ...spinnerPositionStyle }}>
			<div className='loader' style={{ ...style }}>
				<div className='bounce1' />
				<div className='bounce2' />
				<div className='bounce3' />
			</div>
		</div>
	);
};

export default Spinner;