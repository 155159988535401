import React from 'react';
import { withRouter } from 'react-router-dom';

import Navigation from '../Navigation';

const Footer = ({ lang }) => (
	<footer>
		<Navigation lang={lang} />
	</footer>
);

export default withRouter(Footer);