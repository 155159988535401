import React from 'react';
import * as THEME from '../../constants/theme.js';

const SettingsPage = ({ lang, setLang, theme, setTheme }) => {
	const isIE = window.document.documentMode;

	const onLanguageChange = (event) => {
		event.preventDefault();
		setLang(event.target.value);
	}

	const onThemeChange = (event) => {
		event.preventDefault();
		setTheme(THEME[event.target.value]);
	}

	return (
		<section>
			<div className="wrapper">
				<h1 style={{ textAlign: 'center' }}>{ lang === 'es' ? 'Preferencias' : 'Settings' }</h1>
				
				<h3>{ lang === 'es' ? 'Idioma' : 'Language' }</h3>
				<form style={{ marginBottom: '1rem' }}>
					<label className="radio">
						<input
							name="language"
							value="en"
							type="radio"
							checked={ lang === 'en' }
							onChange={onLanguageChange}
						/>
						<span>{ lang === 'es' ? 'Inglés' : 'English' }</span>
					</label>
					<label className="radio">
						<input
							name="language"
							value="es"
							type="radio"
							checked={ lang === 'es' }
							onChange={onLanguageChange}
						/>
						<span>{ lang === 'es' ? 'Español' : 'Spanish' }</span>
					</label>
				</form>

				{ !isIE ? 
					<>
						<h3>{ lang === 'es' ? 'Configuración de Pantalla' : 'Display Settings' }</h3>
						<form>
							<label className="radio">
								<input
									name="theme"
									value="LIGHT"
									type="radio"
									checked={ theme.name === 'light' }
									onChange={onThemeChange}
								/>
								<span>{ lang === 'es' ? 'Modo Claro' : 'Light Mode' }</span>
							</label>
							<label className="radio">
								<input
									name="theme"
									value="DARK"
									type="radio"
									checked={ theme.name === 'dark' }
									onChange={onThemeChange}
								/>
								<span>{ lang === 'es' ? 'Modo Oscuro' : 'Dark Mode' }</span>
							</label>
						</form>
					</>
				: null }
			</div>
		</section>
	);
};

export default SettingsPage;