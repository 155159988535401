import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const Footnote = () => {
	const fnModalRef = useRef();
	const fnModalBodyRef = useRef();
	const { pathname } = useLocation();

	useEffect(() => {
		fnModalRef.current.classList.remove('visible');
		fnModalBodyRef.current.innerHTML = '';
	}, [pathname]);

	return (
		<div className="modal" id="fn-modal" ref={fnModalRef}>
			<div id="fn-modal-body" ref={fnModalBodyRef} />
			<span className="modal-close" id="fn-modal-close" />
		</div>
	);
}

export default Footnote;