import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import Navigation from '../Navigation';

const Header = ({ lang }) => (
	<header>
		<div className="logo">
			<Link to={ROUTES.HOME}>{ lang === 'es' ? 'Inicio' : 'Home' }</Link>
		</div>

		<Navigation lang={lang} />
	</header>
);

export default withRouter(Header);