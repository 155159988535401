import React, { Component } from 'react';
import { Workbox } from 'workbox-window';

import Spinner from '../Spinner';

class Notification extends Component {
	constructor(props) {
		super(props);

		this.notification = React.createRef();
		this.refresh = React.createRef();
		this.dismiss = React.createRef();

		this.state = {
			loading: false
		};
	}

	componentDidMount() {
		if ('serviceWorker' in navigator) {
			window.addEventListener('load', () => {
				const wb = new Workbox('/service-worker.js');

				wb.addEventListener('waiting', (event) => {
					this.refresh.current.addEventListener('click', (event) => {
						wb.addEventListener('controlling', (event) => {
							this.setState({ loading: true });
							window.location.reload();
						});
						
						wb.messageSW({type: 'SKIP_WAITING'});
					});
					
					this.dismiss.current.addEventListener('click', (event) => {
						this.notification.current.classList.remove('visible');
					});

					this.notification.current.classList.add('visible');
				});

				wb.register();
			});
		}
	}

	render() {
		const { loading } = this.state;

		return (
			<div className="modal" id="notification" ref={this.notification}>
				<div style={{ opacity: loading ? '0' : '1' }}>
					A new version is available.
					<div className="buttons">
						<button ref={this.dismiss}>Dismiss</button>
						<button ref={this.refresh}>Refresh</button>
					</div>
				</div>
				{ loading && <Spinner delay={0} button={true} style={{ transform: 'scale(0.75)' }} /> }
			</div>
		);
	}
}

export default Notification;