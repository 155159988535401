import React, { useRef } from 'react';

const OfflineMessage = () => {
	const offlineRef = useRef();

	const onModalCloseClick = () => {
		offlineRef.current.classList.remove('visible');
	};

	return (
		<div className="modal visible" id="offline" ref={offlineRef}>
			You are currently offline. <span style={{ whiteSpace: 'nowrap' }}>Check your network connection.</span>
			<span className="modal-close" onClick={onModalCloseClick} />
		</div>
	);
}

export default OfflineMessage;