import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { onSnapshot, orderBy, query } from 'firebase/firestore';
import idbReady from 'safari-14-idb-fix';

import Spinner from '../Spinner';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

class CollectionsList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			timePassed: false,
			collections: [],
			lang: this.props.lang
		};
	}

	setLoadingTimer = () => setTimeout(() => {
		this.setState({ timePassed: true });
	}, 5000);

	componentDidMount() {
		idbReady().then(() => {
			this.setState({ loading: true });
			this.loadingTimer = this.setLoadingTimer;

			this.query = query(this.props.firebase.collections(), orderBy('order'));
			this.unsubscribe = onSnapshot(this.query, (snapshot) => {
				if (snapshot.empty) {
					this.props.history.replace(ROUTES.ERROR);
					return;
				}

				const collectionsList = [];
				
				snapshot.forEach((doc) => {
					collectionsList.push({
						...doc.data(),
						id: doc.id
					});
				});

				this.setState({
					collections: collectionsList,
					loading: false
				});
			}, (error) => {
				console.log(error);
				this.props.history.push(ROUTES.ERROR, { error: error });
			});
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.timePassed !== this.state.timePassed) {
			if (this.state.loading) {
				this.props.history.replace(ROUTES.ERROR);
			}
		}
	}

	componentWillUnmount() {
		clearTimeout(this.loadingTimer);
		this.unsubscribe();
	}

	render() {
		const { collections, loading } = this.state;

		const collectionsList = collections.map((item) => (
			<li key={item.id}>
				<Link to={`/${item.id}`}>
					<div className="collection-icon" style={{ backgroundImage: `url(/icons/${item.id}.svg)`, maskImage:`url(/icons/${item.id}.svg`, WebkitMaskImage: `url(/icons/${item.id}.svg` }} />
					<span>{item.title[this.state.lang]}</span>
				</Link>
			</li>
		));

		return (
			<>
				{
					this.props.match.isExact ?
						<section>
							<div className="wrapper">
								<h1 style={{ textAlign: 'center' }}>
									{ this.state.lang === 'es' ?
										<>El Catecismo Menor <span style={{ whiteSpace: 'nowrap' }}>de Lutero</span></> 
									:
										<>Luther&rsquo;s Small Catechism</>
									}
								</h1>
								{ loading ?
									<Spinner />
								:
									<ul className="collections-list">{collectionsList}</ul>
								}
							</div>
						</section>
						: null
				}
			</>
		);
	}
}
 
export default withRouter(withFirebase(CollectionsList));