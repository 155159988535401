import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { withFirebase } from '../Firebase';

const CookieMessage = (props) => {
	const handleAcceptCookie = () => {
		props.firebase.doSetConsent('granted');
	}

	const handleDeclineCookie = () => {
		props.firebase.doSetConsent('denied');
	}

	const cookieMessage = 'This website stores cookies on your computer. These cookies are used to collect information about how you interact with our website and allow us to remember you. We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors both on this website and other media. To find out more about the cookies we use, see our <a href="https://www.augsburgfortress.org/info/privacy/" target="_blank" rel="noreferrer">Privacy Policy</a>.';
	
	const cookieConfig = {
		enableDeclineButton: true,
		declineButtonText: 'Decline',
		buttonText: 'Accept',
		onAccept: handleAcceptCookie,
		onDecline: handleDeclineCookie,
		disableStyles: true,
		containerClasses: 'modal visible',
		contentClasses: 'cookie-message',
		buttonWrapperClasses: 'buttons',
		customContainerAttributes: {id: 'cookie-consent'}
	};

	return (
		<CookieConsent {...cookieConfig}><span dangerouslySetInnerHTML={{__html: cookieMessage}} /></CookieConsent>
	);
}

export default withFirebase(CookieMessage);