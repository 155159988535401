import React, { useEffect, useLayoutEffect } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch
} from 'react-router-dom';
import { Offline } from 'react-detect-offline';

import CookieMessage from '../CookieMessage';
import Notification from '../Notification';
import OfflineMessage from '../OfflineMessage';
import Footnote from '../Footnote';
import ScrollToTop from '../ScrollToTop';
import Header from '../Header';
import Footer from '../Footer';
import CollectionsList from '../CollectionsList';
import Collection from '../Collection';
import NotFoundPage from '../404';
import HelpPage from '../Help';
import AboutPage from '../About';
import SettingsPage from '../Settings';
import Maintenance from '../Maintenance';
import ErrorPage from '../Error';

import * as THEME from '../../constants/theme.js';
import * as ROUTES from '../../constants/routes';
import useLocalStorage from '../../useLocalStorage';

const App = () => {
	const maintenance = false;
	const isIE = window.document.documentMode;

	const [lang, setLang] = useLocalStorage('lang', '');
	const [theme, setTheme] = useLocalStorage('theme', '');

	if (!theme.name) {
		if (isIE) {
			setTheme(THEME.LIGHT);
		} else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
			setTheme(THEME.DARK);
		} else {
			setTheme(THEME.LIGHT);
		}
	}

	useLayoutEffect(() => {
		document.documentElement.setAttribute('data-theme', theme.name);
		document.querySelector('meta[name="theme-color"]').setAttribute('content', theme.color);
	}, [theme]);

	if (!lang) {
		let navigatorLang = window.navigator.language.substring(0,2);
		navigatorLang = navigatorLang === 'es' ? navigatorLang : 'en';
		setLang(navigatorLang);
	}

	useEffect(() => {
		document.documentElement.setAttribute('lang', lang);
	}, [lang]);

	// bfcache fix
	window.onpageshow = function(event) {
		if (event.persisted) {
			window.location.reload();
		}
	}

	if (maintenance) {
		return (
			<>
				<Maintenance />
				<Modals />
			</>
		);
	} else {
		return (
			<Router>
				<ScrollToTop />
				<Header lang={lang} />

				<main>
					<Switch>
						<Route exact path={ROUTES.HOME} component={() => (<CollectionsList lang={lang} />)} />
						<Route exact path={ROUTES.HELP} component={() => (<HelpPage lang={lang} />)} />
						<Route exact path={ROUTES.ABOUT} component={() => (<AboutPage lang={lang} />)} />
						<Route exact path={ROUTES.SETTINGS} component={() => (<SettingsPage lang={lang} setLang={setLang} theme={theme} setTheme={setTheme} />)} />
						<Route exact path={ROUTES.NOT_FOUND} component={NotFoundPage} />
						<Route exact path={ROUTES.ERROR} component={ErrorPage} />
						<Route exact path={ROUTES.COLLECTION} component={() => (<Collection lang={lang} />)} />
						<Route component={NotFoundPage} />
					</Switch>
				</main>

				<Footer lang={lang} />
				<Modals />
			</Router>
		);
	}
};

const Modals = () => (
	<div className="modals">
		<Footnote />
		<Offline>
			<OfflineMessage />
		</Offline>
		<Notification />
		<CookieMessage />
	</div>
);

export default App;