import React from 'react';
import EllipsisLightMode from '../../images/ellipsis.svg';
import EllipsisDarkMode from '../../images/ellipsis_light.svg';
import ShareLightMode from '../../images/share.svg';
import ShareDarkMode from '../../images/share_light.svg';

const HelpPage = ({ lang }) => (
	<section>
		<div className="wrapper">
			{ lang === 'es' ?
				<>
					<h1 style={{ textAlign: 'center' }}>Añada la app a su teléfono o tableta</h1>

					<h3>Safari para Apple iOS</h3>

					<p>Si tiene un dispositivo Apple iOS, utilice Safari para añadir el icono de la aplicación a su pantalla de inicio y tener así fácil acceso a <em>El Catecismo Menor de Lutero</em>.</p>

					<ol>
						<li>Abra la aplicación Safari.</li>
						<li>Vaya a <strong>catechism.augsburgfortress.org</strong>.</li>
						<li>Haga clic en el icono de Share Menu (Compartir) (
							<img className="light-theme" src={ShareLightMode} style={{ width: "17px", padding: "0 2px", transform: "translateY(2.5px)", display: "inline-block" }} alt="icono de compartir" />
							<img className="dark-theme" src={ShareDarkMode} style={{ width: "17px", padding: "0 2px", transform: "translateY(2.5px)", display: "inline-block" }} alt="icono de compartir" />
						).</li>
						<li>Seleccione &ldquo;Add to Home Screen&rdquo; (Añadir a la pantalla de inicio) y sigua las instrucciones indicadas.</li>
					</ol>

					<h3>Google Chrome para Android</h3>

					<p>Si tiene un dispositivo Android, utilice Google Chrome. Cuando vaya por primera vez a <strong>catechism.augsburgfortress.org</strong> en su teléfono o tableta, le pedirá que lo añada a su pantalla de inicio. Haga clic para agregar el icono de la aplicación y tener así fácil acceso a <em>El Catecismo Menor de Lutero</em>.</p>

					<p>Si no recibe ese aviso cuando usa Chrome en un dispositivo Android, siga estas instrucciones:</p>

					<ol>
						<li>Abra la app de Chrome.</li>
						<li>Vaya a <strong>catechism.augsburgfortress.org</strong>.</li>
						<li>Toque el menú del icono (
							<img className="light-theme" src={EllipsisLightMode} style={{ height: "15px", padding: "0 3px", transform: "translateY(2.5px)", display: "inline-block" }} alt="icono de menú" />
							<img className="dark-theme" src={EllipsisDarkMode} style={{ height: "15px", padding: "0 3px", transform: "translateY(2.5px)", display: "inline-block" }} alt="icono de menú" />
						) y toque &ldquo;Install app&rdquo; (Instalar aplicación).</li>
					</ol>
				</>
			:
				<>
					<h1 style={{ textAlign: 'center' }}>Add the app to your phone or tablet</h1>

					<h3>Safari for Apple iOS</h3>

					<p>Use Safari with an Apple iOS device to add the app&rsquo;s icon to your home screen. This will give you easy access to Luther&rsquo;s Small Catechism.</p>

					<ol>
						<li>Open the Safari app.</li>
						<li>Open <strong>catechism.augsburgfortress.org</strong>.</li>
						<li>Click the Share Menu icon (
							<img className="light-theme" src={ShareLightMode} style={{ width: "17px", padding: "0 2px", transform: "translateY(2.5px)", display: "inline-block" }} alt="share icon" />
							<img className="dark-theme" src={ShareDarkMode} style={{ width: "17px", padding: "0 2px", transform: "translateY(2.5px)", display: "inline-block" }} alt="share icon" />
						).</li>
						<li>Select &ldquo;Add to Home Screen&rdquo; and follow the instructions given.</li>
					</ol>

					<h3>Google Chrome for Android</h3>

					<p>Use Google Chrome with an Android device. When you visit <strong>catechism.augsburgfortress.org</strong> on a phone or tablet for the first time, you should be prompted to add it to your home screen. Click the prompt to add the app&rsquo;s icon. This will give you easy access to Luther&rsquo;s Small Catechism.</p>

					<p>If you do not get this prompt using Chrome, follow these instructions. Note: These instructions will only work on Android. For an iOS device, please use the Safari instructions above.</p>

					<ol>
						<li>Open the Chrome app.</li>
						<li>Open <strong>catechism.augsburgfortress.org</strong>.</li>
						<li>Tap the menu icon (
							<img className="light-theme" src={EllipsisLightMode} style={{ height: "15px", padding: "0 3px", transform: "translateY(2.5px)", display: "inline-block" }} alt="menu icon" />
							<img className="dark-theme" src={EllipsisDarkMode} style={{ height: "15px", padding: "0 3px", transform: "translateY(2.5px)", display: "inline-block" }} alt="menu icon" />
						) and tap &ldquo;Install app.&rdquo;</li>
					</ol>
				</>
			}
		</div>
	</section>
);

export default HelpPage;