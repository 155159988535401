import React from 'react';

const ErrorPage = (error = '') => {
	return (
		<section>
			<div className="wrapper" style={{ textAlign: "center" }}>
				<h1>Something went wrong.</h1>

				<p>
					Please try again later.<br/>
					Return to our <a href="/">home page</a>.
				</p>

				{ error.message && <p>{error.message}</p> }
			</div>
		</section>
	);
};

export default ErrorPage;