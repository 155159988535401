import React, { Component, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { onSnapshot } from 'firebase/firestore';

import Spinner from '../Spinner';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const CollectionBase = (props) => {
	useEffect(() => {
		const fnref = document.getElementsByClassName('fnref');
		const fnModal = document.getElementById('fn-modal');
		const fnModalBody = document.getElementById('fn-modal-body');
		const fnModalClose = document.getElementById('fn-modal-close');
		
		fnref.forEach((item) => {
			item.addEventListener('click', (event) => {
				const value = event.target.dataset.value;
				const fn = document.getElementById(value).innerHTML;
				
				fnModalBody.innerHTML = fn;

				if (!fnModal.classList.contains('visible')) {
					fnModal.classList.add('visible');
				}
			});
		});

		fnModalClose.addEventListener('click', () => {
			if (fnModal.classList.contains('visible')) {
				fnModal.classList.remove('visible');
				fnModalBody.innerHTML = '';
			}
		});
	}, []);

	return (
		<div className="wrapper collection">
			<div className="collection-header">
				<div className="collection-icon" style={{ backgroundImage: `url(/icons/${props.id}.svg)`, maskImage:`url(/icons/${props.id}.svg)`, WebkitMaskImage: `url(/icons/${props.id}.svg)` }} />
				<h1>{props.title}</h1>
			</div>
			<div className="collection-body" dangerouslySetInnerHTML={{ __html: props.html }} />
		</div>
	);
};

class Collection extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			loading: false,
			collection: {},
			collectionId: this.props.match.params.collectionId,
			lang: this.props.lang
		};
	}
		
	componentDidMount() {
		this.setState({ 
			loading: true
		});

		this.fetchData(this.state.collectionId);
	}

	componentDidUpdate(prevProps) {
		const currentId = this.props.match.params.collectionId;
		const prevId = prevProps.match.params.collectionId;
		
		if (prevId !== currentId) {
			this.setState({
				loading: true,
				collectionId: currentId 
			});
			
			this.fetchData(currentId);
		}
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	fetchData(id) {
		this.unsubscribe = onSnapshot(this.props.firebase.collection(id), (doc) => {
			if (!doc.data()) {
				this.props.history.replace(ROUTES.NOT_FOUND);
				return;
			}

			const collectionObject = doc.data();
			
			this.setState({
				loading: false,
				collection: collectionObject,
				html: collectionObject.html[this.state.lang],
				title: collectionObject.title[this.state.lang]
			});
		}, (error) => {
			console.error(error);
			this.props.history.push(ROUTES.ERROR, { error: error });
		});
	}

	render() {
		const {
			loading,
			collectionId,
			html,
			title
		} = this.state;

		if (loading) {
			return (
				<section>
					<div className="wrapper">
						<Spinner />
					</div>
				</section>
			);
		}

		if (collectionId) {
			return (
				<section>
					<CollectionBase key={collectionId} id={collectionId} title={title} html={html} />
				</section>
			);
		} else {
			return <Redirect to={ROUTES.HOME} />
		}
	}
}

export default withRouter(withFirebase(Collection));