export const HOME = '/';
export const SIGN_IN = '/signin';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/reset';
export const COLLECTIONS = '/sections';
export const COLLECTION = '/:collectionId';
export const NOT_FOUND = '/404';
export const HELP = '/help';
export const ABOUT = '/about';
export const SETTINGS = '/settings';
export const ERROR = '/error';